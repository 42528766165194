<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/withook_36.svg"
            headerText="Setup Your custom hook process"
            @continue="continueForm"
            @reset="resetForm"
            ref="tabbedForm"
            details
            :dirty="dirty"
            :fetched="fetched"
            :update="updateWitHook"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-form="{step}">
                <b-form-group label="Name" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.withookForm.name.$model"
                                type="text"
                                placeholder="Enter WitHook name"
                                id="withook-name"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('withookForm.name', step)"
                                invalid="This field is required"
                                valid="WitHook name is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="URL" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.withookForm.url.$model"
                                type="text"
                                placeholder="Enter Endpoint URL"
                                :id="`${formId}-url`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('withookForm.url', step)"
                                invalid="This field is required"
                                valid="WitHook URL is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Timeout in seconds" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-spinbutton
                                v-model="$v.withookForm.timeout.$model"
                                min="60"
                                max="900"
                                step="60"
                                :id="`${formId}-timeout`"
                            ></b-form-spinbutton>
                            <Feedback
                                :state="validateRef('withookForm.timeout', step)"
                                invalid="This field must be between 60 and 900"
                                valid="WitHook Timeout is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Request Method" label-for="request-method" :label-cols="2" :horizontal="true">
                    <b-form-radio-group id="request-method" v-model="withookForm.method" name="request-method">
                        <b-form-radio value="GET">GET</b-form-radio>
                        <b-form-radio value="POST">POST</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

                <b-form-group label="Headers" label-for="request-headers" :label-cols="2" :horizontal="true">
                    <b-row v-for="(header, index) in withookForm.headers" :key="index" class="withook-header-row">
                        <b-col md="4">
                            <b-form-input v-model="header[0]" type="text" placeholder="Header Key"></b-form-input>
                        </b-col>
                        <b-col md="4">
                            <b-form-input v-model="header[1]" type="text" placeholder="Header Value"></b-form-input>
                        </b-col>
                        <b-col>
                            <b-button
                                @click="withookForm.headers.splice(index, 1)"
                                size="sm"
                                variant="danger"
                                :id="`${formId}-remove-header`"
                            >
                                <i class="fa fa-minus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row id="add-withook-header-row">
                        <b-col>
                            <b-button
                                :disabled="$v.withookForm.headers.$invalid"
                                @click="withookForm.headers.push([null, null])"
                                size="sm"
                                variant="primary"
                                :id="`${formId}-add-header`"
                            >
                                <i class="fa fa-plus"></i>Add
                            </b-button>
                            <Feedback
                                :state="validateRef('withookForm.headers', step)"
                                invalid="Each Header must have a key and a value"
                                valid="Headers are valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="Query Parameters"
                    label-for="request-query"
                    :label-cols="2"
                    :horizontal="true"
                    v-if="withookForm.method === 'GET'"
                >
                    <b-row v-for="(query, index) in withookForm.queryParams" :key="index" class="withook-query-row">
                        <b-col md="4">
                            <b-form-input v-model="query[0]" type="text" placeholder="Query Param Key"></b-form-input>
                        </b-col>
                        <b-col md="4">
                            <b-form-input v-model="query[1]" type="text" placeholder="Query Param Value"></b-form-input>
                        </b-col>
                        <b-col>
                            <b-button
                                @click="withookForm.queryParams.splice(index, 1)"
                                size="sm"
                                variant="danger"
                                :id="`${formId}-remove-query`"
                            >
                                <i class="fa fa-minus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row id="add-withook-query-row">
                        <b-col>
                            <b-button
                                :disabled="$v.withookForm.queryParams.$invalid"
                                @click="withookForm.queryParams.push([null, null])"
                                size="sm"
                                variant="primary"
                                :id="`${formId}-add-query`"
                            >
                                <i class="fa fa-plus"></i>Add
                            </b-button>
                            <Feedback
                                :state="validateRef('withookForm.queryParams', step)"
                                invalid="Each Query Parameter must have a key and a value"
                                valid="Query Parameters are valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="Workflow Variables"
                    label-for="request-workflowenv"
                    :label-cols="2"
                    :horizontal="true"
                >
                    <b-row
                        v-for="(variable, index) in withookForm.workflowEnv"
                        :key="index"
                        class="withook-workflowenv-row"
                    >
                        <b-col md="3">
                            <wit-select
                                :options="workflowEnvToOptions"
                                v-model="variable.to"
                                placeholder="Add to"
                            ></wit-select>
                        </b-col>
                        <b-col md="3">
                            <b-form-input
                                v-model="variable.key"
                                type="text"
                                placeholder="Variable Key"
                                class="withook-workflowenv-key"
                            ></b-form-input>
                        </b-col>
                        <b-col md="3">
                            <wit-select
                                :options="workflowEnvValueOptions"
                                v-model="variable.value"
                                placeholder="Variable Value"
                            ></wit-select>
                        </b-col>
                        <b-col>
                            <b-button
                                @click="withookForm.workflowEnv.splice(index, 1)"
                                size="sm"
                                variant="danger"
                                :id="`${formId}-remove-variable`"
                            >
                                <i class="fa fa-minus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row id="add-withook-query-row">
                        <b-col>
                            <b-button
                                :disabled="$v.withookForm.workflowEnv.$invalid"
                                @click="withookForm.workflowEnv.push({to: null, key: null, value: null})"
                                size="sm"
                                variant="primary"
                                :id="`${formId}-add-variable`"
                            >
                                <i class="fa fa-plus"></i>Add
                            </b-button>
                            <Feedback
                                :state="validateRef('withookForm.workflowEnv', step)"
                                invalid="Each Variable must have all 3 values filled in"
                                valid="Variables are valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="Request Body"
                    label-for="request-body"
                    :label-cols="2"
                    :horizontal="true"
                    v-if="withookForm.method === 'POST'"
                >
                    <b-row id="withook-json-row">
                        <b-col md="8">
                            <b-form-textarea
                                v-model="$v.withookForm.body.$model"
                                placeholder="Enter WitHook JSON"
                                rows="3"
                                max-rows="6"
                                :id="`${formId}-body`"
                            ></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <Feedback
                                :state="validateRef('withookForm.body', step)"
                                invalid="This field must be a valid JSON or left empty"
                                valid="Body is valid"
                            ></Feedback>
                            <b-button
                                @click="formatJSON"
                                size="sm"
                                variant="primary"
                                :disabled="$v.withookForm.body.$invalid"
                                :id="`${formId}-format`"
                                >Format</b-button
                            >
                        </b-col>
                    </b-row>
                </b-form-group>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {formMixin} from '@/mixins/formMixin'
import {processMixin} from '@/mixins/processMixin'
import {filterDirty} from '@/shared/filterDirty.js'
import {fromEntries} from '@/shared/fromEntries'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
    },
    data() {
        return {
            formId: 'withook-update-form',
            cannotContinue: true,
            withookForm: {
                name: '',
                url: '',
                timeout: 60,
                method: 'GET',
                headers: [],
                queryParams: [],
                workflowEnv: [],
                body: '',
            },
            currentWithookForm: {},
            workflowEnvValueOptions: ['date', 'hour'],
            workflowEnvToOptions: ['queryParams', 'body', 'headers'],
            withookError: {
                state: null,
                message: null,
            },
        }
    },
    mixins: [processMixin, formMixin, validationMixin],
    validations: {
        withookForm: {
            name: {required},
            url: {
                required,
                re: value =>
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,})?(\/.*)?$/.test(
                        value
                    ),
            },
            timeout: {
                required,
                between: value => value >= 60 && value <= 900,
            },
            method: {required},
            headers: {
                valid: function() {
                    return this.withookForm.headers.every(entry => entry[0] && entry[1])
                },
            },
            queryParams: {
                valid: function() {
                    return this.withookForm.queryParams.every(entry => entry[0] && entry[1])
                },
            },
            body: {
                valid: function() {
                    if (this.withookForm.body) {
                        try {
                            JSON.stringify(JSON.parse(this.withookForm.body), null, 2)
                            return true
                        } catch {
                            return false
                        }
                    }
                    return true
                },
            },
            workflowEnv: {
                valid: function() {
                    return this.withookForm.workflowEnv.every(variable => variable.to && variable.key && variable.value)
                },
            },
        },
    },
    computed: {
        ...mapGetters({
            pipelines: 'pipeline/pipelines',
            activeProject: 'project/active',
        }),
        steps() {
            return [
                {
                    name: 'WitHook Settings',
                    invalid: this.$v.withookForm.$invalid,
                },
            ]
        },
        dirty() {
            const processFormDirty = filterDirty(this.withookForm, this.currentWithookForm)
            return Object.keys(processFormDirty).length > 0
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Fetching WitHook Process...`)
        await this.fetchProcess()
        this.fetched = true
        this.$store.commit('loading/PROCESSED')
    },
    methods: {
        async fetchProcess() {
            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/withooks/${this.$route.params.id}`
            )
            this.process = response.data.data
            this.withookForm = {
                name: this.process.name,
                url: this.process.url,
                timeout: this.process.timeout,
                method: this.process.method,
                headers: Object.entries(this.process.headers),
                queryParams: Object.entries(this.process.queryParams),
                workflowEnv: this.process.workflowEnv,
                body: this.process.body,
            }
            this.currentWithookForm = cloneDeep(this.withookForm)
        },

        formatJSON() {
            if (!this.$v.withookForm.body.$invalid) {
                this.withookForm.body = JSON.stringify(JSON.parse(this.withookForm.body), null, 2)
            }
        },

        async updateWitHook() {
            const processFormDirty = filterDirty(this.withookForm, this.currentWithookForm)
            if (Object.keys(processFormDirty).length > 0) {
                const {body, queryParams, headers, ...baseForm} = processFormDirty
                let data = {...baseForm, headers: headers ? fromEntries(headers) : undefined}
                if (this.withookForm.method === 'GET' && processFormDirty.queryParams) {
                    data.queryParams = fromEntries(queryParams)
                }
                if (this.withookForm.method === 'POST' && processFormDirty.body) {
                    data.body = body
                }
                this.$store.commit('loading/PROCESSING', `Updating WitHook Process...`)
                try {
                    await this.axios.put(`${process.env.VUE_APP_NODE_API_HOST}/withooks/${this.$route.params.id}`, data)

                    this.facebookError = {}
                    this.$v.$reset()

                    this.$store.commit('loading/PROCESSED')
                    this.currentWithookForm = cloneDeep(this.withookForm)
                } catch (exception) {
                    this.$store.commit('loading/PROCESSED')
                    this.facebookError.state = false
                    this.facebookError.message = exception.response.data.additionalInfo
                    this.$errorHandler.report(exception, 'Could not update form')
                }
            }
        },
    },
}
</script>

<style lang="scss">
#request-method,
#request-timeout {
    margin-top: 6px;
}
.withook-header-row,
.withook-query-row,
.withook-workflowenv-row {
    margin-bottom: 10px;
}
#withook-json-row {
    margin-bottom: 10px;
}
.withook-workflowenv-key {
    min-height: 40px;
}
</style>
